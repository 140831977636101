import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function CreateUser() {
    const navigate = useNavigate();

    const [inputs, setInputs] = useState({})

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        axios.post('https://263cloudstore.co.zw/api/user/create', inputs).then(function(response){
            console.log(response.data);
            navigate('/');
        });
         
    }
    return (
        <div>
            <h1>Create user</h1>
            <form onSubmit={handleSubmit}>
                <table cellSpacing="10">
                    <tbody>
                        <tr>
                            <th>
                                <label>Username: </label>
                            </th>
                            <td>
                                <input type="text" name="username" onChange={handleChange} />    
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label>Password: </label>
                            </th>
                            <td>
                                <input type="text" name="pass" onChange={handleChange} />  
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2 " align="right">
                                <button>Save</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                
                
                <br/>
                
                <br/>
               
            </form>
        </div>
    )
}