import React from 'react';
import Login from './Login';
import Dashboard from './Admin_Dashboard';
import RatesPage from './Rates_Page';
import Currencies from './currencies';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import CreateUser from './create';
import EditUser from './Edit';
import PrivateRoutes from './Protected';



function App(){
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<RatesPage />} />
                <Route path ="login" element={<Login/>} />
                <Route element={<PrivateRoutes/>}>
                    <Route path ="dashboard" element={<Dashboard/>}  exact/>
                    <Route path ="currencies" element={<Currencies/>} exact/>
                    
                    <Route path ="create" element={<CreateUser/>} exact/>
                    <Route path ="currencies/rate/:id/edit" element={<EditUser/>} exact/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
