import { useState, useEffect } from 'react';
import './Login.css';
import { useNavigate } from 'react-router-dom';


function Login() {

  const navigate = useNavigate();
  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  const [error, setError] = useState("");
  const [msg, setMsg] = useState(""); 

  useEffect(() => {
      let login = localStorage.getItem("login");
      if(login){
        navigate("/dashboard");
      }
      let loginStatus = localStorage.getItem("loginStatus");
      if(loginStatus){
        setError(loginStatus);
        setTimeout(function(){
          localStorage.clear();
          window.location.reload();
        }, 3000);
      }
      setTimeout(function(){
        setMsg("");
      }, 3000)
  }, [msg]);

  const handleInputChange = (e, type) => {
    switch(type){
      case "user":
          setError("");
          setUser(e.target.value);
          if(e.target.value === ""){
            setError("Username has been left blank");
          }

        break;
      case "pass":
        setError("");
        setPass(e.target.value);
        if(e.target.value === ""){
          setError("Password has been left blank");
        }

        break;
      default:
    }
  }
  function loginSubmit(){
    if(user !=="" && pass !=""){
      var url = "https://263cloudstore.co.zw/api/login.php";
      var headers = {
        "Accept": "application/json",
        "Content-type": "application/json"
      };
      var Data = {
          user: user,
          pass: pass
      };
      fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(Data)
      }).then((response) => response.json())
      .then((response) => {
        console.log(response);
        if(response[0].result === "Invalid username!" || response[0].result === "Invalid password!"){
          setError(response[0].result);
      } else {
              setMsg(response[0].result);
              setTimeout(function(){
                localStorage.setItem('login', true);
                localStorage.setItem('user', user);
                navigate("/dashboard");
              }, 2000)
      }
      }).catch((err) => {
        setError(err);
        console.log(err)
      })
    } else {
      setError("All field are required!")
    }
  }

  return (
    <div>
      <div className="box-root flex-flex flex-direction--column"
    style={{ minHeight: "100vh", flexGrow: 1 }}>
        <div className="loginbackground box-background--white padding-top--64">
        <div className="loginbackground-gridContainer">
            <div
              className="box-root flex-flex"
              style={{ gridArea: "top / start / 8 / end" }}
            >
              <div
                className="box-root"
                style={{
                  backgroundImage:
                    "linear-gradient(white 0%, rgb(247, 250, 252) 33%)",
                  flexGrow: 1
                }}
              ></div>
            </div>
            <div
              className="box-root flex-flex"
              style={{ gridArea: "4 / 2 / auto / 5" }}
            >
              <div
                className="box-root box-divider--light-all-2 animationLeftRight tans3s"
                style={{ flexGrow: 1 }}
              />
            </div>
            <div
              className="box-root flex-flex"
              style={{ gridArea: "6 / start / auto / 2" }}
            >
              <div
                className="box-root box-background--blue800"
                style={{ flexGrow: 1 }}
              />
            </div>
            <div
              className="box-root flex-flex"
              style={{ gridArea: "7 / start / auto / 4" }}
            >
              <div
                className="box-root box-background--blue animationLeftRight"
                style={{ flexGrow: 1 }}
              />
            </div>
            <div
              className="box-root flex-flex"
              style={{ gridArea: "8 / 4 / auto / 6" }}
            >
              <div
                className="box-root box-background--gray100 animationLeftRight tans3s"
                style={{ flexGrow: 1 }}
              />
            </div>
            <div
              className="box-root flex-flex"
              style={{ gridArea: "2 / 15 / auto / end" }}
            >
              <div
                className="box-root box-background--cyan200 animationRightLeft tans4s"
                style={{ flexGrow: 1 }}
              />
            </div>
            <div
              className="box-root flex-flex"
              style={{ gridArea: "3 / 14 / auto / end" }}
            >
              <div
                className="box-root box-background--blue animationRightLeft"
                style={{ flexGrow: 1 }}
              />
            </div>
            <div
              className="box-root flex-flex"
              style={{ gridArea: "4 / 17 / auto / 20" }}
            >
              <div
                className="box-root box-background--gray100 animationRightLeft tans4s"
                style={{ flexGrow: 1 }}
              />
            </div>
            <div
              className="box-root flex-flex"
              style={{ gridArea: "5 / 14 / auto / 17" }}
            >
              <div
                className="box-root box-divider--light-all-2 animationRightLeft tans3s"
                style={{ flexGrow: 1 }}
              />
            </div>
          </div>
        </div>
        <div className="box-root padding-top--24 flex-flex flex-direction--column"
      style={{ flexGrow: 1, zIndex: 9 }}>
          <div className="formbg-outer">
            <div className="formbg">
              <div className="formbg-inner padding-horizontal--48">
                <div className="logo">
                  <img src={require("./resources/metro logo.png")} className="logo" alt=''/>
                </div>
                <span className="padding-bottom--15">Sign in to your account</span>
                <div className="row h-100 justify-content-center align-items-center">
                    <div>
                        <p>
                            {
                                error !==""?
                                <div style={{color: '#842029'}}><b>{error}</b></div> :
                                <div style={{color: '#badbcc'}}><b>{msg}</b></div>
                            }
                        </p>
                        <div className="form-outline mb-4">
                            <label className="form-label" htmlFor="username">Username</label>
                            <input type="text" id="username" className="form-control form-control-lg" value={user} onChange={(e) => handleInputChange(e, "user")}/>
                            
                        </div>
                        <div className="form-outline mb-4">
                            <label className="form-label" htmlFor="pass">Password</label>
                            <input type="password" id="pass" className="form-control form-control-lg" value={pass} onChange={(e) => handleInputChange(e, "pass")}/>
                            
                        </div>
                        <div className="pt-1 mb-4 d-flex align-items-center justify-content-center">
                            <input type="submit" defaultValue="Login" className="btn btn-dark btn-lg btn-block" onClick={loginSubmit} style={{marginLeft: '50'}}/>

                        </div>
                    </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    
</div>
  );
}

export default Login;
