import {Outlet, Navigate} from 'react-router-dom';
 
const PrivateRoutes = () => {
    const user = localStorage.getItem('user');
    let auth = {'token':false}
    if (user!=null){
            auth = {'token':true}
    }
    
    return(
        auth.token ? <Outlet/> : <Navigate to="/login"/>
    )
}

export default PrivateRoutes