import axios from "axios";
import { useEffect, useState } from "react";
import {Link, useNavigate } from "react-router-dom";




export default function Currencies() {
    
    var url = "";
    const navigate = useNavigate();

    const [rates, setRates] = useState([]);
    useEffect(() => {
        getRates();
    }, []);

    function getRates() {
        axios.get('https://263cloudstore.co.zw/api/rates').then(function(response) {
            console.log(response.data);
            setRates(response.data); 
        });
    }

    function logoutSubmit(){
        localStorage.setItem("login", "");
        localStorage.setItem("loginStatus", "Logged out successfully!");
        navigate("/login");
    }

    function getImg(index){
        if (index === 1){
                url ="./images/logos/united-states.jpg";
        } else if(index === 2) {
                url ="./images/logos/Great Britain.jpg";
        } else if (index === 3) {
                url = "./images/logos/South-africa.jpg";
        }
        return url;
    }
    
    
    return (
        <div>
            <div
                className="page-wrapper"
                id="main-wrapper"
                data-layout="vertical"
                data-navbarbg="skin6"
                data-sidebartype="full"
                data-sidebar-position="fixed"
                data-header-position="fixed"
            >
                {/* Sidebar Start */}
                <aside className="left-sidebar">
                {/* Sidebar scroll*/}
                <div>
                    <div className="brand-logo d-flex align-items-center justify-content-between">
                    <a href="./index.html" className="text-nowrap logo-img">
                        <img
                        src="./images/logos/metro logo.png"
                        width={250}
                        alt=""
                        />
                        {/*Metro logo*/}
                    </a>
                    <div
                        className="close-btn d-xl-none d-block sidebartoggler cursor-pointer"
                        id="sidebarCollapse"
                    >
                        <i className="ti ti-x fs-8" />
                    </div>
                    </div>
                    {/* Sidebar navigation*/}
                    <nav className="sidebar-nav scroll-sidebar" data-simplebar="">
                    <ul id="sidebarnav">
                        <li className="nav-small-cap">
                        <i className="ti ti-dots nav-small-cap-icon fs-4" />
                        <span className="hide-menu">Home</span>
                        </li>
                        <li className="sidebar-item">
                        <a
                            className="sidebar-link"
                            href="dashboard"
                            aria-expanded="false"
                        >
                            <span>
                            <i className="ti ti-layout-dashboard" />
                            </span>
                            <span className="hide-menu">Dashboard</span>
                        </a>
                        </li>
                        <li className="nav-small-cap">
                        <i className="ti ti-dots nav-small-cap-icon fs-4" />
                        <span className="hide-menu">APPLICATIONS</span>
                        </li>
                        <li className="sidebar-item">
                        <a
                            className="sidebar-link"
                            href="currencies"
                            aria-expanded="false"
                        >
                            <span>
                            <i className="ti ti-article" />
                            </span>
                            <span className="hide-menu">Currencies</span>
                        </a>
                        </li>
                        <li className="sidebar-item">
                        <a
                            className="sidebar-link"
                            href="/"
                            aria-expanded="false"
                        >
                            <span>
                            <i className="ti ti-alert-circle" />
                            </span>
                            <span className="hide-menu">Rates</span>
                        </a>
                        </li>
                        <li className="nav-small-cap">
                        <i className="ti ti-dots nav-small-cap-icon fs-4" />
                        <span className="hide-menu">AUTHENTICATION</span>
                        </li>
                        <li className="sidebar-item">
                        <div className="row justify-content-center">
                            <span onClick={logoutSubmit}>
                            <i className="ti ti-login" />
                        &nbsp;
                            <i className="hide-menu" >Logout</i>
                            </span>
                            
                        </div>
                           
                        </li>
                    </ul>
                    </nav>
                    {/* End Sidebar navigation */}
                </div>
                {/* End Sidebar scroll*/}
                </aside>
                {/*  Sidebar End */}
                {/*  Main wrapper */}
                <div className="body-wrapper">
                {/*  Header Start */}
                <header className="app-header">
                    <nav className="navbar navbar-expand-lg navbar-light">
                    <div
                        className="navbar-collapse justify-content-end px-0"
                        id="navbarNav"
                    >
                        <ul className="navbar-nav flex-row ms-auto align-items-center justify-content-end">
                        <li className="nav-item dropdown">
                            <img
                                src="./images/profile/user-1.jpg"
                                alt=""
                                width={35}
                                height={35}
                                className="rounded-circle"
                            />
                        </li>
                        </ul>
                    </div>
                    </nav>
                </header>
                {/*  Header End */}
                <div className="container-fluid">
                    {/*  Row 1 */}
                    <div className="row">
                    <h4>
                        <b>Currencies</b>
                    </h4>
                    </div>
                    <div className="col">
                    <div className="row">
                        <div className="col">
                        {/* Metro Hypermarket current rates */}
                        <div className="card overflow-hidden">
                            <div className="card-body p-4">
                            <h5 className="card-title mb-9 fw-semibold">
                                <b>Currencies</b>
                            </h5>
                            
                            <table className="table table-borderless">
                                <thead style={{ backgroundColor: "#186618" }}>
                                    <tr>
                                        
                                        <th scope="col" style={{ color: "#fafafa" }}>Currency Name</th>
                                        <th scope="col" style={{ color: "#fafafa" }}>Buying</th>
                                        <th scope="col" style={{ color: "#fafafa" }}>Selling</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {rates.map((rate, key) =>
                                            <tr key={key}>
                                                <th scope="row">
                                                    <div className="d-flex justify-content-start">
                                                        <div>

                                                            <img src={getImg(rate.id)} alt="" height="40rem" width="40rem" className="rounded-circle" ></img>
                                                    </div>
                                                    &nbsp;&nbsp;
                                                    <div>
                                                        <h6>
                                                            <b>{rate.Abv}</b>
                                                        </h6>
                                                        <h6>{rate.name}</h6>
                                                    </div>
                                                    </div>
                                                </th>
                                                <td>
                                                    <strong style={{ fontSize: 35 }}>
                                                        <b>{rate.Buying}</b>
                                                        
                                                    </strong>
                                                </td>
                                                <td>
                                                    <strong style={{ fontSize: 35 }}>
                                                        <b>{rate.Selling}</b>
                                                        
                                                    </strong>
                                                </td>
                                                <td>
                                                    &nbsp;&nbsp;
                                                
                                                    <Link to={`rate/${rate.id}/edit`}><button type="button" class="btn btn-light"><b style={{color: "#1E90FF"}}>UPDATE</b></button></Link>

                                                    
                                                </td>
                                            </tr>
                                    )}
                                </tbody>
                                
                            </table>
                            <div className="col-4">
                                <div className="d-flex justify-content-center">
                                <div id="breakup" />
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="py-6 px-6 text-center justify-content-center">
                        <p className="mb-0 fs-4">
                        All Rights Reserved. Developed by
                        <a
                            href="https://irs.co.zw/"
                            className="pe-1 text-primary text-decoration-underline"
                        >
                            {" "}
                            Info-Relay Systems.
                        </a>{" "}
                        </p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
           
            
        </div>
    )
}