import axios from "axios"
import { useEffect, useState } from "react";
import './Rates_Page.css';


export default function RatesPage() {
    var url = "";
    var abv, buying, selling = "";
    
    const [rates, setRates] = useState([])
    useEffect(() => {
        getRates();
    }, []);

    const [currentDateTime, setCurrentDateTime] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const formattedDateTime = () => {
        const options = { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
        return currentDateTime.toLocaleString('en-US', options);
      };


    function getRates() {
        axios.get('https://263cloudstore.co.zw/api/rates').then(function(response) {
            console.log(response.data);
            setRates(response.data); 
        }).catch((err) =>{
            console.log(err)
        });
    }

    function getBuySell(x, y, z){
        abv = x;
        if(abv === "USD"){
            buying = y;
            selling = z;
            localStorage.setItem('buying', buying);
            localStorage.setItem('selling', selling);
            
            return abv
        } else{
            return abv;
        }
        

    }
    function getImg(index){
        if (index === 1){
                url ="./images/logos/united-states.jpg";
        } else if(index === 2) {
                url ="./images/logos/Great Britain.jpg";
        } else if (index === 3) {
                url = "./images/logos/South-africa.jpg";
        }
        return url;
    }
    return (
        <div>
            <div className="row">
                <div className="row" style={{ backgroundColor: "#15153b" }}>
                &nbsp;&nbsp;
                    <div className="d-flex justify-content-center">
                        <h1 style={{ color: "#fafafa" }}>Bureau de Change</h1>
                    </div>
                    <div className="d-flex justify-content-center">
                        <h3 style={{ color: "#fafafa" }}>Exchange Rates</h3>
                    </div>
                    &nbsp;&nbsp;
                </div>
                &nbsp;&nbsp;
                <div className="card overflow-hidden ">
                    <div className="card-body p-4">
                        <h5 className="card-title mb-9 fw-semibold">
                        <b> FOREIGN CURRENCY</b>
                        </h5>
                        <table className="table table-borderless">
                            <thead style={{ backgroundColor: "#186618" }}>
                                <tr>
                                    
                                    <th scope="col" style={{ color: "#fafafa" }}>Currency Name</th>
                                    <th scope="col" style={{ color: "#fafafa" }}>Buying</th>
                                    <th scope="col" style={{ color: "#fafafa" }}>Selling</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rates.map((rate, key) =>
                                        <tr key={key}>
                                            <th scope="row">
                                                <div className="d-flex justify-content-start">
                                                    <div>

                                                        <img src={getImg(rate.id)} alt="" height="40rem" width="40rem" className="rounded-circle" ></img>
                                                </div>
                                                &nbsp;&nbsp;
                                                <div>
                                                    <h6>
                                                        <b>{getBuySell(rate.Abv, rate.Buying, rate.Selling)}</b>
                                                    </h6>
                                                    <h6>{rate.name}</h6>
                                                </div>
                                                </div>
                                            </th>
                                            <td>
                                                <strong style={{ fontSize: 35 }}>
                                                    <b>{rate.Buying}</b>
                                                </strong>
                                            </td>
                                            <td>
                                                <strong style={{ fontSize: 35 }}>
                                                    <b>{rate.Selling}</b>
                                                </strong>
                                            </td>  
                                        </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className='Container'>
                        <div className='marquee'>{formattedDateTime()}</div>
                    </div>
                </div>
                <div className="p-5 text-center justify-content-center"  style={{ backgroundColor: "#15153b" }}>
                    <p className="mb-0 fs-4" style={{ color: "#fafafa" }}>All Rights Reserved. Developed by
                    <a href="https://irs.co.zw/" className="pe-1 text-primary text-decoration-underline"> {" "}
                                    Info-Relay Systems.</a>{" "}</p>

                </div>
            </div>
        </div>
        
        
    )
}