import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";


export default function EditUser() {
    const navigate = useNavigate();

    const [inputs, setInputs] = useState({});

    const {id} = useParams();

    useEffect(() => {
        getRate();
    }, []);

    function exit(){
        navigate("/dashboard");
    }

    function getRate() {
        axios.get(`https://263cloudstore.co.zw/api/rate/${id}`).then(function(response) {
            console.log(response.data);
            setInputs(response.data); 
        });
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        axios.put(`https://263cloudstore.co.zw/api/rate/${id}/edit`, inputs).then(function(response){
            console.log(response.data);
            navigate('/currencies');
        });
         
    }
    return (
        <div>
            <div className="row">
                <div className="row" style={{ backgroundColor: "#15153b" }}>
                &nbsp;&nbsp;
                    <div className="d-flex justify-content-center">
                        <h1 style={{ color: "#fafafa" }}>Bureau de Change</h1>
                    </div>
                    <div className="d-flex justify-content-center">
                        <h3 style={{ color: "#fafafa" }}>Metro Money Exchange Rates</h3>
                    </div>
                    &nbsp;&nbsp;
                </div>
                &nbsp;&nbsp;
                <div className="card overflow-hidden ">
                    <div className="card-body p-4" style={{marginTop:"1.5%"}}>
                        <h1 className="card-title mb-9 fw-semibold" >
                        <b> EDIT RATES</b>
                        </h1>
                    </div>
                    <div>
                        <form width="50%">
                            <div className="col d-flex justify-space-between" style={{marginBottom:"2.5%", width: "50%"}}>
                                <span  style={{width:"200px"}}>Currency Name</span>
                                <input value={inputs.name} type="text" className="form-control" id="in3" name="name" onChange={handleChange}></input>
                            </div>
                            <div className="col d-flex justify-space-between" style={{marginBottom:"2.5%", width: "50%"}}>
                                <span  style={{width:"200px"}}>Buying</span>
                                <input value={inputs.Buying} type="text" className="form-control " name="Buying" onChange={handleChange}></input>
                            </div>
                            <div className="col d-flex justify-space-between" style={{marginBottom:"2.5%", width: "50%"}}>
                                <span  style={{width:"200px"}}>Selling</span>
                                <input value={inputs.Selling} type="text" className="form-control" name="Selling" onChange={handleChange}></input>
                            </div>
                            <div className="col d-flex justify-space-between" style={{marginBottom:"2.5%", width: "50%"}}>
                                <button className="btn btn-outline-success btn-lg" style={{marginLeft:"25%", marginRight: "25%" }} onClick={handleSubmit}>UPDATE</button>
                                <button className="btn btn-outline-warning btn-lg" onClick={exit}>Return to Dashboard</button>
                            </div>
                        </form>
                    </div> 
                </div>
                <div className="p-5 text-center justify-content-center"  style={{ backgroundColor: "#15153b" }}>
                    <p className="mb-0 fs-4" style={{ color: "#fafafa" }}>All Rights Reserved. Developed by
                    <a href="https://irs.co.zw/" className="pe-1 text-primary text-decoration-underline"> {" "}
                                    Info-Relay Systems.</a>{" "}</p>

                </div>
            </div>
        </div>

       
    )
}